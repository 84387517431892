<template>
	<div class="buttom">
		<div class="navbox">
			<div class="item" v-for="(item,index) in tailList" :key="index">
				<div class="name">{{item.name}}</div>
				<div class="navItem" :class="child.url.length?'navHove':''" v-for="child in item.child"
					:key="child.name" @click="navClick(child.url)">{{child.name}}{{child.tel|| ""}}</div>
			</div>
		</div>
		<div class="filings">{{filings}}</div>
	</div>
</template>
<script>
	import {
		tailList,
		filings
	} from "../../const/layout.js"
	export default {
		data() {
			return {
				tailList,
				filings
			}
		},
		methods: {
			navClick(url) {
				if (url.length != 0) {
					this.$router.push("/pc" + url)
				}
			}
		}
	}
</script>
<style scoped lang="scss">
	.buttom {
		width: 100%;
		background: rgb(45, 48, 50);
		.navbox {
			width: 100%;
			box-sizing: border-box;
			padding: 24rem 160rem;
			display: flex;
			.item {
				margin-right: 80rem;
				.name {
					color: rgb(255, 255, 255);
					font-size: 20rem;
					font-weight: 700;
					line-height: 48rem;
					letter-spacing: 1rem;
				}
				.navItem {
					color: rgb(255, 255, 255);
					font-family: 微软雅黑;
					font-size: 14rem;
					font-weight: 400;
					line-height: 30rem;

				}
				.navHove {
					cursor: pointer;
				}
				.navHove:hover {
					color: #FF7800;
				}
			}
		}
		.filings {
			color: rgb(153, 153, 153);
			font-family: 微软雅黑;
			font-size: 16rem;
			font-weight: 400;
			line-height: 48rem;
			letter-spacing: 1rem;
			text-align: center;
		}
	}
</style>