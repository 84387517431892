import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}
import layout from '@/views/pc/layout/index.vue';
import h5Layout from '@/views/h5/layout/index.vue';
let client = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
let browserTypes = client ? "/h5" : "/pc"
// 2.配置系统所有路由页面，浏览器通过path路径加载对应组件
const routes = [
	{
		path: '/',
		redirect: browserTypes
	},
	{
		path: '/pc',
		redirect: '/pc/home',
		component: () => import('@/views/layout/index.vue'),
		children: [{
				path: 'home',
				component: () => import('@/views/pc/home/home.vue'),
				meta: {
					title: '首页'
				}
			},
			{
				path: '/pc/group',
				component: layout,
				redirect: '/pc/group/profile',
				meta: {
					title: '关于集团'
				},
				children: [{
						path: 'profile',
						component: () => import('@/views/pc/group/profile.vue'),
						meta: {
							title: '集团简介'
						}
					},
					{
						path: 'director',
						component: () => import('@/views/pc/group/director.vue'),
						meta: {
							title: '集团董事'
						}
					},
					{
						path: 'culture',
						component: () => import('@/views/pc/group/culture.vue'),
						meta: {
							title: '集团文化'
						}
					},
					{
						path: 'honor',
						component: () => import('@/views/pc/group/honor.vue'),
						meta: {
							title: '荣誉证书'
						}
					},
					{
						path: 'law',
						component: () => import('@/views/pc/group/law.vue'),
						meta: {
							title: '法律申明'
						}
					},
				]
			},
			{
				path: '/pc/industry',
				component: layout,
				redirect: '/pc/industry/engineering',
				meta: {
					title: '集团产业'
				},
				children: [{
						path: 'engineering',
						component: () => import('@/views/pc/industry/engineering.vue'),
						meta: {
							title: '工程房产商服'
						}
					},
					{
						path: 'ecommerce',
						component: () => import('@/views/pc/industry/ecommerce.vue'),
						meta: {
							title: '电商数储'
						}
					},
					{
						path: 'intelligence',
						component: () => import('@/views/pc/industry/intelligence.vue'),
						meta: {
							title: '人工智能应用'
						}
					},
				]
			},
			{
				path: '/pc/welfare',
				component: layout,
				redirect: '/pc/welfare/index',
				meta: {
					title: '胡众公益'
				},
				children: [{
						path: 'index',
						component: () => import('@/views/pc/welfare/index.vue'),
						meta: {
							title: '胡众公益'
						}
					},
					{
						path: 'details',
						component: () => import('@/views/pc/welfare/details.vue'),
						meta: {
							title: '公益详情'
						}
					},
				]
			},
			{
				path: '/pc/news',
				component: layout,
				redirect: '/pc/news/index',
				meta: {
					title: '新闻中心'
				},
				children: [{
						path: 'index',
						component: () => import('@/views/pc/news/index.vue'),
						meta: {
							title: '新闻中心'
						}
					},
					{
						path: 'details',
						component: () => import('@/views/pc/news/details.vue'),
						meta: {
							title: '新闻详情'
						}
					},
				]
			},
			{
				path: '/pc/contact',
				component: layout,
				redirect: '/pc/contact/index',
				meta: {
					title: '联系我们'
				},
				children: [{
					path: 'index',
					component: () => import('@/views/pc/contact/index.vue'),
					meta: {
						title: '联系我们'
					}
				}, ]
			},
		]
	},
	{
		path: '/h5',
		redirect: '/h5/home',
		component: () => import('@/views/h5/layout/index.vue'),
		children: [{
				path: 'home',
				component: () => import('@/views/h5/home.vue'),
				meta: {
					title: '首页'
				}
			},
			{
				path: 'group/profile',
				component: () => import('@/views/h5/group/profile.vue'),
				meta: {
					title: '集团简介'
				}
			},
			{
				path: 'group/director',
				component: () => import('@/views/h5/group/director.vue'),
				meta: {
					title: '集团董事'
				}
			},
			{
				path: 'group/culture',
				component: () => import('@/views/h5/group/culture.vue'),
				meta: {
					title: '集团文化'
				}
			},
			{
				path: 'group/honor',
				component: () => import('@/views/h5/group/honor.vue'),
				meta: {
					title: '荣誉证书'
				}
			},
			{
				path: 'group/law',
				component: () => import('@/views/h5/group/law.vue'),
				meta: {
					title: '法律申明'
				}
			},
			{
				path: 'industry/engineering',
				component: () => import('@/views/h5/industry/engineering.vue'),
				meta: {
					title: '工程房产商服'
				}
			},
			{
				path: 'industry/ecommerce',
				component: () => import('@/views/h5/industry/ecommerce.vue'),
				meta: {
					title: '电商数储'
				}
			},
			{
				path: 'industry/intelligence',
				component: () => import('@/views/h5/industry/intelligence.vue'),
				meta: {
					title: '人工智能应用'
				}
			},
			{
				path: 'welfare/index',
				component: () => import('@/views/h5/welfare/index.vue'),
				meta: {
					title: '胡众公益'
				}
			},
			{
				path: 'welfare/details',
				component: () => import('@/views/h5/welfare/details.vue'),
				meta: {
					title: '详情'
				}
			},
			{
				path: 'news/index',
				component: () => import('@/views/h5/news/index.vue'),
				meta: {
					title: '新闻中心'
				}
			},
			{
				path: 'news/details',
				component: () => import('@/views/h5/news/details.vue'),
				meta: {
					title: '详情'
				}
			},
			{
				path: 'contact/index',
				component: () => import('@/views/h5/contact/index.vue'),
				meta: {
					title: '联系我们'
				}
			}
		]
	},
]
const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	let client = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	let browserTypes = client ? "/h5" : "/pc"
	let fullPath = to.fullPath
	if (fullPath.indexOf(browserTypes) > -1) {
		next()
	} else {
		router.push(browserTypes)
	}
})
export default router