<template>
	<div class="layout">
		<div class="nav" :class="status || changeStatus?'filterPage':''">
			<img src="../../../assets/logo2.png" v-if="status  || changeStatus" @click="navIndex" />
			<img src="../../../assets/logo1.png" @click="navIndex" />
			<div class="navList">
				<div class="item" :class="!item.child.length?'hoverItem':''" v-for="(item,index) in navList"
					:key="index">
					<div class="name" @click="navClick(item.url)">{{item.name}}</div>
					<div class="navItemList">
						<div class="navItem" v-for="child in item.child" :key="child.name" @click="navClick(child.url)">
							{{child.name}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pages">
			<router-view></router-view>
		</div>
		<img class="navTop" src="../../../assets/navTop.png" v-show="status || changeStatus" @click="navTop" />
		<tail />
	</div>
</template>
<script>
	import {
		navList
	} from "../../const/layout.js"
	import tail from "../components/tail.vue"
	export default {
		components: {
			tail
		},
		data() {
			return {
				navList,
				status: false,
				changeStatus: false
			}
		},
		created() {
			this.status = this.filterateRoute(this.$route.path)
		},
		mounted() {
			window.addEventListener("scroll", this.scrolling);
		},
		methods: {
			scrolling(e) {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				let changeHeight = 492 * window.innerWidth / 1920
				if (scrollTop >= changeHeight && !this.status) {
					this.changeStatus = true
				}
				if (scrollTop < changeHeight && !this.status) {
					this.changeStatus = false
				}
			},
			navIndex() {
				this.$router.push("/home")
			},
			navClick(url) {
				if (url.length != 0) {
					this.$router.push("/pc" + url)
				}
			},
			navTop() {
				document.documentElement.scrollTop = 0
			},
			filterateRoute(fullPath) {
				const arr = [
					"/pc/welfare/details",
					"/pc/news/details",
				]
				if (arr.indexOf(fullPath) >= 0) {
					return true
				} else {
					return false
				}
			},
		},
		watch: {
			$route(to, from) {
				document.documentElement.scrollTop = 0
				this.status = this.filterateRoute(to.path)
			},
		}
	}
</script>

<style scoped lang="scss">
	.layout {
		width: 100%;
		.nav {
			width: 100%;
			position: fixed;
			z-index: 15;
			height: 88rem;
			left: 0;
			box-sizing: border-box;
			padding: 12rem 160rem;
			display: flex;
			justify-content: space-between;
			img {
				width: 148rem;
				height: 54rem;
				cursor: pointer;
			}
			.navList {
				display: flex;
				.item {
					position: relative;
					margin-left: 72rem;
					cursor: pointer;
					.name {
						text-align: right;
						line-height: 54rem;
						color: rgb(255, 255, 255);
						font-size: 18rem;
					}
					.navItemList {
						width: 130rem;
						position: absolute;
						z-index: 20;
						left: -29rem;
						top: 54rem;
						background: rgba(0, 0, 0, 0.5);
						display: none;
					}
					.navItemList:hover {
						display: block;
					}
					.navItem {
						text-align: center;
						color: rgb(255, 255, 255);
						font-size: 16rem;
						line-height: 50rem;
					}
					.navItem:hover {
						color: #FF7800;
					}
				}
				.item:hover .navItemList {
					display: block;
				}
				.hoverItem:hover .name {
					color: #FF7800 !important;
				}
			}
		}
		.filterPage {
			background: #FFFFFF;
			.navList {
				.item {
					.name {
						color: #333333;
					}
					.navItem {
						color: #FFFFFF;
					}
				}
			}
		}
		.navTop {
			width: 100rem;
			height: 100rem;
			position: fixed;
			right: 32rem;
			bottom: 450rem;
			cursor: pointer;
		}
	}
	.pages {
		width: 100%;
	}
</style>