export  const navList = [
	{
		name: "关于集团",
		enName: "About us",
		url: "",
		child: [{
				name: "集团简介",
				url: "/group/profile"
			},
			{
				name: "集团董事",
				url: "/group/director"
			},
			{
				name: "集团文化",
				url: "/group/culture"
			},
			{
				name: "荣誉证书",
				url: "/group/honor"
			},
			{
				name: "法律申明",
				url: "/group/law"
			}
		],
		background:'rgb(0, 0, 0)'
	},
	{
		name: "集团产业",
		enName: "Our industry",
		url: "",
		child: [{
				name: "工程房产商服",
				url: "/industry/engineering"
			},
			{
				name: "电商数储",
				url: "/industry/ecommerce"
			},
			{
				name: "人工智能应用",
				url: "/industry/intelligence"
			}
		],
		background:'rgb(17, 17, 17)'
	},
	{
		name: "胡众公益",
		enName: "Our charity",
		url: "/welfare/index",
		child: [],
		background:'rgb(34, 34, 34)'
	},
	{
		name: "新闻中心",
		enName: "News center",
		url: "/news/index",
		child: [],
		background:'rgb(51, 51, 51)'
	},
	{
		name: "联系我们",
		enName: "Contact us",
		url: "/contact/index",
		child: [],
		background:'rgb(68, 68, 68)'
	}
]
export  const tailList= [
	{
		name: "关于集团",
		child: [{
				name: "集团简介",
				url: "/group/profile"
			},
			{
				name: "集团董事",
				url: "/group/director"
			},
			{
				name: "集团文化",
				url: "/group/culture"
			},
			{
				name: "荣誉证书",
				url: "/group/honor"
			},
			{
				name: "法律申明",
				url: "/group/law"
			}
		]
	},
	{
		name: "集团产业",
		child: [{
				name: "工程房产商服",
				url: "/industry/engineering"
			},
			{
				name: "电商数储",
				url: "/industry/ecommerce"
			},
			{
				name: "人工智能应用",
				url: "/industry/intelligence"
			}
		]
	},
	{
		name: "联系电话",
		child: [{
				name: "北京胡众国翼中心 : ",
				url: "",
				tel:"010-63488888/18612038888"
			},
			{
				name: "北京胡众庄园 : ",
				url: "",
				tel:"010-63488888/18612038888"
			},
			{
				name: "成都胡众九眼桥篡街 (酒吧街) : ",
				url: "",
				tel:"010-63488888/18612038888"
			},
			{
				name: "成都胡众大厦 : ",
				url: "",
				tel:"010-63488888/18612038888"
			},
			{
				name: "上海胡众商服中心尚足堂 : ",
				tel:"010-63488888/18612038888",
				url: ""
			}
		]
	},
]
export  const filings= 'Copyright @ 2020 版权所有 北京诚信模网信息科技有限公司成都分公司 ICP备12032835-11号'
export  const h5filings= [
	"Copyright @ 2020 版权所有 北京诚信模网信息",
	"科技有限公司成都分公司 ICP备12032835-11号"
]


