<template>
	<div class="h5Page">
		<div class="topBox">
			<div class="topNav" v-show="navName != '首页'">
				<img src="../../../assets/h5/navBack.png" v-if="navName != '首页'" @click="navBack" />
				{{ navName }}
			</div>
			<div class="navBox">
				<img class="logo" src="../../../assets/h5/logo.png" @click="navClick('/home')" />
				<img class="nav" src="../../../assets/h5/nav.png" @click="navstatus = true" />
			</div>
		</div>
		<div class="navList" v-if="navstatus">
			<img src="../../../assets/h5/out.jpg" alt="" class="out" @click="navstatus = false">
			<van-collapse v-model="activeNames" :border="false">
				<van-collapse-item :name="item.name" :is-link="false" :border="false" v-for="(item, index) in navList"
					:key="index">
					<template #title>
						<div class="collapseTitle" @click="navClick(item.url)" :style="{ background: item.background }">{{
							item.name }} <p>{{ item.enName }}</p>
						</div>
					</template>
					<div class="collapseBody" @click="navClick(items.url)" :style="{ background: item.background }"
						v-for="(items, index) in item.child" :key="index">{{ items.name }}</div>
				</van-collapse-item>
			</van-collapse>
		</div>
		<div class="conbox" ref="page" :style="{'paddingTop' : navName == '首页' ? '60rem' : '104rem'}">
			<router-view></router-view>
		</div>
		<!-- <div class="tail">
			<div class="item" v-for="(item,index) in tailList" :key="index">
				<div class="name">{{item.name}}</div>
				<div class="navItem">
					<div class="chil" v-for="(child) in item.child"  :key="child.name" @click="navClick(child.url)">
						<p>{{child.name}}</p>
						<p class="tel" v-if="child.tel">{{child.tel}}</p>
					</div>
				</div>
			</div>
			<div class="filings">
				<p v-for="(item,index) in h5filings" :key="index">{{item}}</p>
			</div>
		</div> -->
		<div class="h5filings" ref="h5filings" v-if="navName != '首页'">
			<p v-for="(item, index) in h5filings" :key="index">{{ item }}</p>
		</div>
		<!-- <img class="navTop" src="../../../assets/h5/navTop.png" v-show="changeStatus" @click="navTop"/> -->
	</div>
</template>
<script>
let client = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
let clientWidth = client ? 375 : 1920
const baseSize = 1
function setRem() {
	const scale = document.documentElement.clientWidth / clientWidth
	document.documentElement.style.fontSize = baseSize * scale + 'px'
}
setRem()
window.onresize = function () {
	setRem()
}
import {
	navList,
	tailList,
	h5filings
} from "../../const/layout.js"
export default {
	data() {
		return {
			activeNames: [],
			navList,
			navstatus: false,
			navName: "首页",
			tailList,
			h5filings,
			// changeStatus:false,
		}
	},
	created() {
		this.navName = this.$route.meta.title
		this.activeNames = [this.navName]
	},
	mounted() {
		// window.addEventListener("scroll",this.scrolling);
	},
	methods: {
		// scrolling(e){
		// 	let scrollTop =
		// 	window.pageYOffset ||
		// 	document.documentElement.scrollTop ||
		// 	document.body.scrollTop;
		// 	if(scrollTop>=200){
		// 		this.changeStatus = true
		// 	}else{
		// 		this.changeStatus = false
		// 	}
		// },
		navBack() {
			history.back()
		},
		navClick(url) {
			if (url.length != 0) {
				this.navstatus = false
				this.$router.push("/h5" + url)
			}
		},
		// navTop(){
		// 	document.documentElement.scrollTop = 0
		// },
	},
	watch: {
		$route(to, from) {
			this.navName = to.meta.title

			this.activeNames = [this.navName]
			document.documentElement.scrollTop = 0
		},
	}
}
</script>
<style lang="scss" scoped>
.h5Page {
	background: rgb(249, 249, 249);
}

.topBox {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	background: #fff;

	.topNav {
		width: 100%;
		height: 44rem;
		text-align: center;
		line-height: 44rem;
		color: rgb(0, 0, 0);
		font-family: SF Pro Text;
		font-size: 18rem;
		position: relative;

		img {
			width: 12rem;
			height: 21rem;
			position: absolute;
			left: 10rem;
			top: 11.5rem;
		}
	}

	.navBox {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12rem 16rem;

		.logo {
			width: 98rem;
			height: 36rem;
		}

		.nav {
			width: 16rem;
			height: 16rem;
		}

	}
}

.navList {
	width: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	z-index: 100000;
	top: 0;
	bottom: 0;
	overflow: scroll;
	scrollbar-width: none;
	/* firefox */
	-ms-overflow-style: none;
	/* IE 10+ */
	overflow-x: hidden;
	overflow-y: auto;

	::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}


	.out {
		width: 23rem;
		height: 23rem;
		position: absolute;
		top: 20rem;
		right: 20rem;
		z-index: 100000;
	}

	.collapseTitle {
		color: #fff;
		padding: 50rem 70rem;
		font-size: 24rem;

		p {
			padding-top: 5rem;
			font-size: 16rem;
			color: rgba(255, 255, 255, 0.6);
		}
	}

	.collapseBody {
		color: #fff;
		padding: 0rem 70rem 30rem;
		font-size: 14rem;
	}

}

.conbox {
	width: 100%;
	min-height: calc(100vh - 66rem - 104rem);
}

// .tail{
// 	width: 100%;
// 	background: #2D3032;
// 	box-sizing: border-box;
// 	padding: 0 16rem;
// 	padding-top: 8rem;
// 	display: flex;
// 	flex-wrap: wrap;
// 	.item{
// 		margin-right: 32rem;
// 		margin-top: 16rem;
// 		// margin-bottom: 16rem;
// 		.name{
// 			color: rgb(255, 255, 255);
// 			font-size: 20rem;
// 			font-weight: 700;
// 			line-height: 48rem;
// 			letter-spacing: 1rem;
// 		}
// 		.chil{
// 			color: rgb(255, 255, 255);
// 			font-size: 12rem;
// 			font-weight: 400;
// 			line-height: 30rem;
// 			letter-spacing: 1rem;
// 			.tel{
// 				margin-bottom: 16rem;
// 			}
// 		}
// 	}
// 	.filings{
// 		color: rgb(153, 153, 153);
// 		font-size: 12rem;
// 		font-weight: 400;
// 		line-height: 16rem;
// 		letter-spacing: 1rem;
// 		padding: 12rem 0;
// 	}
// }
.h5filings {
	width: 100%;
	color: rgb(153, 153, 153);
	font-size: 12rem;
	line-height: 16rem;
	letter-spacing: 1rem;
	padding: 12rem 16rem;
	background: #2D3032;
	box-sizing: border-box;

	p {
		text-align: center;
	}
}

.navTop {
	width: 50rem;
	height: 50rem;
	position: fixed;
	right: 16rem;
	bottom: 100rem;
	z-index: 1000000;
}</style>