import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
// Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
	router,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')